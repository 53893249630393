import { useState, useEffect } from "react";
import axios from "axios";
import AccessCSS from "../css/access.module.css";
import deleteImg from "../assets/delete.svg";
import userPlusImg from "../assets/user-plus.svg";
import userImg from "../assets/user.svg";
import closeSquareImg from "../assets/close-square.svg";

function Access() {
  const [users, setUsers] = useState([]);
  const [inputValue, setInputValue] = useState("");
  const [roleValue, setRoleValue] = useState("true");
  const [showAddUserTab, setShowAddUserTab] = useState(false);
  const [imagesLoaded, setImagesLoaded] = useState(false);
  const [refreshPage, setRefreshPage] = useState(false);

  const getUserAvatar = (discordId, discriminator, avatar) => {
    if (!avatar && discriminator === undefined) return userImg;

    if (avatar === null) {
      return `https://cdn.discordapp.com/embed/avatars/${
        discriminator % 5
      }.png`;
    } else {
      return `https://cdn.discordapp.com/avatars/${discordId}/${avatar}.png`;
    }
  };

  const handleDeleteUser = (e) => {
    e.preventDefault();

    const discordId = e.currentTarget.id;

    const config = {
      method: "post",
      maxBodyLength: Infinity,
      url: "/api/usersdb",
      headers: {
        "Content-Type": "application/json",
      },
      data: { action: "DELETE", discordId },
    };

    axios(config)
      .then(() => setRefreshPage(!refreshPage))
      .catch(() => setRefreshPage(!refreshPage));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setUsers([]);
    setInputValue("");
    setShowAddUserTab(!showAddUserTab);

    const discordId = inputValue;

    const config = {
      method: "post",
      maxBodyLength: Infinity,
      url: "/api/adduser",
      headers: {
        "Content-Type": "application/json",
      },
      data: { discordId, client: roleValue },
    };

    axios(config)
      .then(() => {
        setRefreshPage(!refreshPage);
        setRoleValue("true");
      })
      .catch(() => {
        setRefreshPage(!refreshPage);
        setRoleValue("true");
      });
  };

  const handleAddUser = (e) => {
    setShowAddUserTab(!showAddUserTab);
    setInputValue("");
  };

  const handleImagesLoad = () => {
    setImagesLoaded(true);
  };

  const handleUpdateUser = (id, role, isRoleTrue) => {
    const [{ discordId, roles }] = users.filter(
      (user) => user.discordId === id
    );
    const hasAccess = roles.length ? true : false;

    const config = {
      method: "post",
      maxBodyLength: Infinity,
      url: "/api/usersdb",
      headers: {
        "Content-Type": "application/json",
      },
      data: {
        action: isRoleTrue ? "INSERT-ROLE" : "DELETE-ROLE",
        discordId,
        role: role,
      },
    };

    axios(config)
      .then(() => setRefreshPage(!refreshPage))
      .catch(() => setRefreshPage(!refreshPage));
  };

  useEffect(() => {
    setUsers([]);

    axios
      .get("/api/usersdb")
      .then((res) => {
        const users = res.data.map((el) => {
          return {
            avatar: el.avatar,
            client: el.client,
            discordId: el.discordId,
            discriminator: el.discriminator,
            roles: el.roles,
            username: el.username,
            rolesMenu: false,
          };
        });

        setUsers(users);
      })
      .catch(() => console.log("err"));
  }, [refreshPage]);

  const handleEscapeKeyPress = (e) => {
    if (e.key === "Escape") {
      setShowAddUserTab(false);
    }
  };

  useEffect(() => {
    window.addEventListener("keydown", handleEscapeKeyPress);

    return () => {
      window.removeEventListener("keydown", handleEscapeKeyPress);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={AccessCSS["access"]}>
      <div className={AccessCSS["add-user-container"]}>
        {imagesLoaded ? (
          <button onClick={handleAddUser} className={AccessCSS["add-button"]}>
            <img
              className={AccessCSS["user-plus-img"]}
              src={userPlusImg}
              alt="user-plus-img"
            />
            Add User
          </button>
        ) : null}
      </div>

      {showAddUserTab ? (
        <div onClick={handleAddUser} className={AccessCSS["add-user-tab"]}>
          <div
            onClick={(e) => e.stopPropagation()}
            className={AccessCSS["add-user-field"]}
          >
            <form
              onSubmit={handleSubmit}
              className={AccessCSS["add-user-from"]}
              method="post"
            >
              <input
                className={AccessCSS["user-input-field"]}
                value={inputValue}
                onChange={(e) => setInputValue(e.target.value)}
                type="text"
                name="discordId"
                id="add-user"
                placeholder="Discord ID"
                required
              />
              <select
                onChange={(e) => setRoleValue(e.target.value)}
                className={AccessCSS["select-option"]}
                name="roles"
              >
                <option value="true">Client</option>
                <option value="false">Admin</option>
              </select>
              <button className={AccessCSS["add-button"]} type="submit">
                Add User
              </button>
            </form>
          </div>
        </div>
      ) : null}

      {users.length && imagesLoaded ? (
        <div className={AccessCSS["main-table-container"]}>
          <table className={AccessCSS["table-container"]}>
            <thead>
              <tr className={AccessCSS["info-graph"]}>
                <th>
                  <p>Name</p>
                </th>
                <th>
                  <p>ID</p>
                </th>
                <th>
                  <p>Role</p>
                </th>
                <th>
                  <p>Access</p>
                </th>
                <th>
                  <p>Actions</p>
                </th>
              </tr>
            </thead>

            <tbody>
              {users.map((user, index) => {
                return (
                  <tr key={index} className={AccessCSS["user-info"]}>
                    <td>
                      <img
                        className={AccessCSS["user-image"]}
                        src={getUserAvatar(
                          user.discordId,
                          user.discriminator,
                          user.avatar
                        )}
                        onError={(e) => {
                          e.target.onerror = null;
                          e.target.src = userImg;
                        }}
                        alt="avatar"
                        draggable="false"
                      />
                      <p className={AccessCSS["user-name"]}>
                        {user.username !== undefined
                          ? user.username
                          : "Not authorized"}
                        {user.discriminator !== "0" &&
                        user.discriminator !== undefined
                          ? `#${user.discriminator}`
                          : null}
                      </p>
                    </td>
                    <td>
                      <p className={AccessCSS["user-id"]}>{user.discordId}</p>
                    </td>
                    <td>
                      <p className={AccessCSS["user-role"]}>
                        {user.client === "false" ? "Admin" : "Client"}
                      </p>
                    </td>
                    <td>
                      {user.roles.map((role) => {
                        return <p className={AccessCSS["user-role"]}>{role}</p>;
                      })}
                    </td>

                    <td className={AccessCSS["user-action"]}>
                      <button
                        id={user.discordId}
                        onClick={() => {
                          const updatedUsers = users.map((e) => {
                            if (e.discordId !== user.discordId && e.rolesMenu)
                              e.rolesMenu = false;
                            if (e.discordId === user.discordId)
                              e.rolesMenu = !user.rolesMenu;
                            return e;
                          });

                          setUsers(updatedUsers);
                        }}
                        className={AccessCSS["update-button"]}
                      >
                        {/* <img className={AccessCSS["delete-img"]} src={deleteImg} alt="delete-img" draggable="false"/> */}
                        Roles
                      </button>
                      {user.rolesMenu ? (
                        <div
                          className={AccessCSS["roles-menu"]}
                          onClick={(e) => e.stopPropagation()}
                        >
                          <div
                            className={AccessCSS["close-box"]}
                            onClick={() => {
                              const updatedUsers = users.map((e) => {
                                if (e.discordId === user.discordId)
                                  e.rolesMenu = !user.rolesMenu;
                                return e;
                              });

                              setUsers(updatedUsers);
                            }}
                          >
                            <img
                              className={AccessCSS["close-img"]}
                              src={closeSquareImg}
                              alt="close-img"
                            />
                          </div>

                          <div className={AccessCSS["role"]}>
                            <input
                              style={{ cursor: "not-allowed" }}
                              checked={false}
                              onChange={() => {}}
                              type="checkbox"
                              name="admin"
                              id=""
                            />
                            <p
                              style={{
                                cursor: "not-allowed",
                                pointerEvents: "all",
                                textDecorationLine: "line-through",
                              }}
                            >
                              Admin
                            </p>
                          </div>
                          <div className={AccessCSS["role"]}>
                            <input
                              checked={
                                user.roles.includes("editor") ? true : false
                              }
                              onChange={() => {
                                let isRoleTrue = undefined;

                                const updatedUsers = users.map((e) => {
                                  if (e.discordId === user.discordId) {
                                    if (e.roles.includes("editor")) {
                                      e.roles = e.roles.filter(
                                        (e) => e !== "editor"
                                      );
                                      isRoleTrue = false;
                                    } else if (!e.roles.includes("editor")) {
                                      e.roles.push("editor");
                                      isRoleTrue = true;
                                    }
                                  }

                                  return e;
                                });

                                if (isRoleTrue === undefined) return;

                                handleUpdateUser(
                                  user.discordId,
                                  "editor",
                                  isRoleTrue
                                );
                                setUsers(updatedUsers);
                              }}
                              type="checkbox"
                              name="editor"
                              id=""
                            />
                            <p>Editor</p>
                          </div>
                          <div className={AccessCSS["role"]}>
                            <input
                              checked={
                                user.roles.includes("premium") ? true : false
                              }
                              onChange={() => {
                                let isRoleTrue = undefined;

                                const updatedUsers = users.map((e) => {
                                  if (e.discordId === user.discordId) {
                                    if (e.roles.includes("premium")) {
                                      e.roles = e.roles.filter(
                                        (e) => e !== "premium"
                                      );
                                      isRoleTrue = false;
                                    } else if (!e.roles.includes("premium")) {
                                      e.roles.push("premium");
                                      isRoleTrue = true;
                                    }
                                  }

                                  return e;
                                });

                                if (isRoleTrue === undefined) return;

                                handleUpdateUser(
                                  user.discordId,
                                  "premium",
                                  isRoleTrue
                                );
                                setUsers(updatedUsers);
                              }}
                              type="checkbox"
                              name="premium"
                              id=""
                            />
                            <p>Premium Client</p>
                          </div>
                        </div>
                      ) : (
                        <></>
                      )}

                      <button
                        id={user.discordId}
                        onClick={handleDeleteUser}
                        className={AccessCSS["delete-button"]}
                      >
                        <img
                          className={AccessCSS["delete-img"]}
                          src={deleteImg}
                          alt="delete-img"
                          draggable="false"
                        />
                      </button>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      ) : (
        <div className={AccessCSS["access-spin"]}>
          <span className={AccessCSS["access-spinner"]}></span>
        </div>
      )}

      <img
        style={{ display: "none" }}
        src={deleteImg}
        alt="delete-img"
        onLoad={handleImagesLoad}
      />
      <img
        style={{ display: "none" }}
        src={userPlusImg}
        alt="user-plus-img"
        onLoad={handleImagesLoad}
      />
    </div>
  );
}

export default Access;
