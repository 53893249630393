import { useState, useEffect } from "react";
import { Routes, Route } from "react-router-dom";
import axios from "axios";
import GeneralCSS from "./css/general.module.css";
import Navbar from "./components/Navbar";
import Content from "./components/Content";

function Settnigs() {
  const [loggedIn, setLoggedIn] = useState(false);

  useEffect(() => {
    axios
      .get("/api/user")
      .then((res) => {
        if (res.data === "unauthorized") {
          window.location.replace("/auth");
        } else {
          setLoggedIn(true);
        }
      })
      .catch(() => {
        console.log("SETTINGS run");
        // window.location.replace("/")
      });
  }, []);

  return (
    <div id={GeneralCSS["root-settings"]}>
      {loggedIn ? (
        <>
          <Navbar />

          <Routes>
            <Route path=":contentId" element={<Content />} />
          </Routes>
        </>
      ) : (
        <div className={GeneralCSS["general-spin"]}>
          <span className={GeneralCSS["general-spinner"]}></span>
        </div>
      )}
    </div>
  );
}

export default Settnigs;
